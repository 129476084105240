import React from 'react';
import { graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/departments/DepartmentLayout';

import Heading from 'components/real-world/Heading';

interface Props {
  data: {
    careerTreks: Core.ImageAttributes;
  };
}

const DepartmentContent: React.FC<Props> = (props) => {
  const { careerTreks } = props.data;

  const headingSections: {
    title: string;
    slug: string;
    description: string;
  }[] = [
    {
      title: 'Student Practicum',
      slug: 'student-practicum',
      description:
        'An opportunity for an extensive, real-world experience in addressing international policy challenges.'
    },
    {
      title: 'Study Trips',
      slug: 'study-trips',
      description:
        'Faculty-led journeys that give students the chance to study complex issues in international locales.'
    }
  ];

  return (
    <Layout departmentSlug="real-world" title="Real World">
      <Heading
        title="Ensuring that immersive learning becomes embedded in every student’s experience at SAIS…
Providing more networking and community-building opportunities — the cornerstone of career development."
        headingSections={headingSections}
      />

      <Core.ContentWrapper>
        <Core.ContentSection>
          <p>
            As part of the new curricular changes at SAIS, the School is
            creating an office devoted entirely to co-curricular and
            experiential learning. Every SAIS graduate remembers the study trip,
            staff ride, or skills course that provided that extra boost that
            helped them land that first job out of SAIS. Immersive learning is a
            powerful tool, and SAIS is committed to building these experiences
            into every student’s time here.
          </p>
          <p>
            The new Office of Co-Curricular and Experiential Learning (CCEL)
            will expand these opportunities and ensure that students all have
            equal access to them. The CCEL office is now part of the new central
            infrastructure at SAIS and will design, implement, and evaluate
            school-wide opportunities and activities, in close collaboration
            with the Global Careers and Student Affairs offices. The goal is to
            offer co-curricular and experiential learning activities to all
            students across all academic fields, also helping promote greater
            diversity of interests and experience within activities.{' '}
          </p>
        </Core.ContentSection>

        <Core.FullWidthImage image={careerTreks} alt="Career Treks" />

        <Core.ContentSection>
          <p>
            The CCEL office will also help further integrate these experiences
            into the MA curriculum, linking them to learning goals related to
            skills (e.g. leadership, professional development, analytical and
            critical thinking) that SAIS students and the job market demand.
            This will allow SAIS to package our experiential learning courses
            and activities in a more coherent way and provide additional
            networking and community-building opportunities for SAIS students —
            the cornerstone of good career development.
          </p>
          <p>
            As part of this effort, the CCEL office is building a library of
            experiential learning opportunities tailored to providing SAIS
            students experiences that will allow them to apply what they learn
            in the classroom to real-life situations. We will be chronicling and
            detailing the experiences we already offer and seeking to add
            additional activities. As an example, two of the most popular
            experiences currently offered are:
          </p>
        </Core.ContentSection>

        <Core.ContentSection id="student-practicum">
          <Core.HorizontalRule />
          <p>
            <Core.BoldText>Practicums:</Core.BoldText> SAIS offers a variety of
            practicum classes that give students an extensive, in-depth, real
            world experience. Students work in teams with external client
            organizations on projects addressing international policy
            challenges. The practicum provides quality research and analysis to
            clients, while students develop their consulting skills and apply
            concepts learned in the classroom to critical problems.
          </p>
        </Core.ContentSection>

        <Core.ContentSection id="study-trips">
          <Core.HorizontalRule />
          <p>
            <Core.BoldText>Study Trips:</Core.BoldText> Each year, the School
            supports faculty-led international trips to study a specific issue
            or complex set of problems facing a country. Students are tasked
            with researching different aspects of the issue, detailing the
            historical context and the current situation on the ground, and
            identifying and setting up interviews with key policymakers and
            officials to meet during the trip. After the trip, each student
            contributes to a policy report and helps present the report to a
            public audience.
          </p>
        </Core.ContentSection>

        <Core.ContentSection>
          <Core.HorizontalRule />
          <p>
            As SAIS moves forward with its new curriculum, the CCEL office will
            expand its offerings of experiential learning opportunities and
            skills courses to reinforce and deepen each student’s classroom time
            at SAIS.
            <br />
            <br />
            <i>—Margel Highet</i>
          </p>
        </Core.ContentSection>

        <Core.MoreResources>
          For more about students’ experiences in the field, visit{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://snapshot.sais-jhu.edu/"
          >
            snapshot.sais-jhu.edu
          </a>
        </Core.MoreResources>
      </Core.ContentWrapper>
    </Layout>
  );
};

export const query = graphql`
  query($directory: String!) {
    careerTreks: file(
      relativePath: { regex: $directory }
      name: { eq: "career-treks-undp-brussels" }
    ) {
      ...FullWidthImage
    }
  }
`;

export default DepartmentContent;
